<template>
  <div class="flex-row-fluid d-flex flex-column ml-lg-8">
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 class="font-weight-bold mb-0 mr-10">Warehouse Overview</h3>
          <div class="input-icon">
            <input type="text" class="form-control" placeholder="Search..." v-model="search" />
            <span>
              <i class="flaticon2-search-1 text-muted"></i>
            </span>
          </div>
        </div>
        <div class="card-toolbar">
          <download-excel-button :excel-data="list" />
        </div>
      </div>
    </div>

    <div v-if="paginatedData.length > 0">
      <div class="card gutter-b">
        <div class="card-body">
          <b-table :items="paginatedData" :fields="fields">
            <template #cell(actions)="row">
              <router-link
                :to="{ name: 'ViewProductMovement', params: { id: row.item.id } }"
                tag="button"
                class="btn btn-secondary btn-sm"
                >View Movement Transaction</router-link
              >
            </template>
          </b-table>
        </div>
      </div>

      <pagination v-model="pagination" />
    </div>

    <no-data v-else></no-data>
  </div>
</template>

<script>
import { getAllProduct } from "@/api/client-operation.api";

import Pagination from "@/components/general/Pagination";
import DownloadExcelButton from "@/components/warehouse/DownloadExcelButton";

import { ref, reactive, onMounted, computed } from "@vue/composition-api";

// import Swal from "sweetalert2";

export default {
  name: "listProduct",
  components: { Pagination, DownloadExcelButton },

  setup() {
    let list = ref([]);
    const search = ref("");

    onMounted(() => refresh());

    const refresh = async () => {
      const response = await getAllProduct();
      list.value = response.data;
    };

    const pagination = reactive({
      currentPage: 1,
      totalRows: computed(() => list.value.length),
      showing: computed(() => paginatedData.value.length),
      itemInPage: 10
    });

    const paginatedData = computed(() => {
      const searchTerm = search.value.toLowerCase();
      let filteredData = list.value.filter(product => {
        for (const key of Object.keys(product)) {
          const val = product[key].toString().toLowerCase();
          if (val.includes(searchTerm)) {
            return true;
          }
        }
        return false;
      });

      let index = (pagination.currentPage - 1) * pagination.itemInPage;
      return filteredData.slice(index, index + pagination.itemInPage);
    });

    return {
      list,
      search,
      pagination,
      paginatedData,
      fields: [
        { key: "belong_to", label: "Belong to" },
        { key: "sku", label: "SKU" },
        { key: "name", label: "Product" },
        { key: "description", label: "Description" },
        { key: "total_qty", label: "Quantity on hand" },
        { key: "rack", label: "At rack" },
        { key: "box", label: "Inside box" },
        { key: "actions", label: "" }
      ]
    };
  }
};
</script>
